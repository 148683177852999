// export const API_URL = 'http://localhost:7777/v3.0/';
// export const API_URL = 'https://api.gr8indeem.com/v3.0/';
// export const API_URL = 'https://api.loverinsure.com/v3.0/';
export const API_URL = 'https://api.hubtravel.co/v3.0/';
// export const API_URL = 'https://dev-api.gr8indeem.com/v3.0/';

export const MEMBER_API_URL = `${API_URL}member/`;
export const CORS_PROXY_URL = 'https://cors.neonetwork.tech/proxy/';

//  Project
export const PROJECT = `${MEMBER_API_URL}project`;

//  Authen
export const LOGIN = `${MEMBER_API_URL}auth/login`;
export const REFRESH_TOKEN = `${MEMBER_API_URL}auth/token`;
export const FORGOT_PASSWORD = `${MEMBER_API_URL}auth/forgot-password`;

//  WALLET
export const WALLET_MEMBER = `${MEMBER_API_URL}wallet/member`;
export const WALLET_MEMBER_GROUPS = `${MEMBER_API_URL}wallet/member/groups`;
export const WALLET_MEMBER_REGISTER = `${MEMBER_API_URL}wallet/member/register`;
export const UPLOAD_WALLET_MEMBER_REGISTER_IMAGE = `${MEMBER_API_URL}wallet/member/register/upload`;
export const WALLET_LEAD_REGISTER = `${MEMBER_API_URL}wallet/lead/register`;
export const WALLET_UPLOAD_CARD = `${MEMBER_API_URL}wallet/member/upload`;

//  Dashboard
export const MEMBER_WEBSITE = `${MEMBER_API_URL}website/member`;
export const GUEST_WEBSITE = `${MEMBER_API_URL}website/guest`;

//  Overview
export const OVERVIEW_LEADS = `${MEMBER_API_URL}overview/leads`;
export const OVERVIEW_INTRODUCTION = `${MEMBER_API_URL}overview/introduction`;
export const OVERVIEW_SIGNUP = `${MEMBER_API_URL}overview/signup`;
export const OVERVIEW_CAMPAIGN = `${MEMBER_API_URL}overview/campaign`;
export const OVERVIEW_MEMBER = `${MEMBER_API_URL}overview/members`;
export const OVERVIEW_INCOME = `${MEMBER_API_URL}overview/income`;

//  Events
export const EVENTS = `${MEMBER_API_URL}events`;

//  News
export const NEWS = `${MEMBER_API_URL}news`;

//  Profile
export const PROFILE = `${MEMBER_API_URL}member/profile`;
export const PROFILE_SETUP = `${MEMBER_API_URL}member/profile/setup`;
export const CHANGE_PASSWORD = `${MEMBER_API_URL}member/profile/password`;
export const UPLOAD_PROFILE_AVATAR = `${MEMBER_API_URL}member/profile/upload`;

export const WEBSITE = `${MEMBER_API_URL}member/website`;
export const UPLOAD_WEBSITE_IMAGE = `${MEMBER_API_URL}member/website/upload`;

//  Page
export const PAGES = `${MEMBER_API_URL}pages`;
export const PAGES_MAIN = `${MEMBER_API_URL}pages/main`;
export const UPLOAD_PAGES_IMAGE = `${MEMBER_API_URL}pages/upload`;
export const UPLOAD_PAGES_EDITOR_IMAGE = `${MEMBER_API_URL}pages/editor/upload`;
export const PAGES_PRODUCTS = `${MEMBER_API_URL}pages/components/products`;
export const PAGE_LOG = `${MEMBER_API_URL}pages/log`;
export const PAGES_RESTORE = `${MEMBER_API_URL}pages/restore`;
export const PAGES_CHECK_UPDATE = `${MEMBER_API_URL}pages/check-update`;

//  Shopping
export const STOCK_INVENTORIES = `${MEMBER_API_URL}stock/inventories`;
export const STOCK_MOVEMENTS = `${MEMBER_API_URL}stock/movements`;

export const PRODUCTS = `${MEMBER_API_URL}shopping/products`;
export const ORDERS = `${MEMBER_API_URL}orders`;
export const UPLOAD_ORDER_IMAGE = `${MEMBER_API_URL}orders/upload`;

export const CUSTOMER_ORDERS = `${MEMBER_API_URL}orders/customer`;
export const WAITING_CUSTOMER_ORDERS = `${MEMBER_API_URL}orders/customer/waiting`;
export const CUSTOMER_ORDERS_TRACKING = `${MEMBER_API_URL}orders/customer/tracking`;
export const CUSTOMER_ORDERS_TRACKING_PRINTING = `${MEMBER_API_URL}orders/customer/tracking/printing`;

export const COURIERS_PRICE = `${MEMBER_API_URL}orders/customer/check-price`;

//  Redeem
export const GIFTS = `${MEMBER_API_URL}redeem/gifts`;
export const REDEEM_HISTORIES = `${MEMBER_API_URL}redeem/histories`;

//  Guest
export const GUESTS = `${MEMBER_API_URL}guest/guests`;
export const GUEST_UPGRADE = `${MEMBER_API_URL}guest/upgrade`;
export const GUEST_UPGRADE_APPROVE = `${MEMBER_API_URL}guest/upgrade/approve`;
export const UPLOAD_GUEST_UPGRADE_IMAGE = `${MEMBER_API_URL}guest/upgrade/upload`;
export const GUESTS_EXPORT = `${MEMBER_API_URL}guest/guests/export`;

//  Teams
export const MEMBER_GUESTS = `${MEMBER_API_URL}member/guests`;
export const MEMBER_GROUPS = `${MEMBER_API_URL}member/groups`;
export const MEMBER_TEAMS = `${MEMBER_API_URL}member/teams`;
export const MEMBER_TEAMS_NETWORK = `${MEMBER_API_URL}member/teams/network`;
export const MEMBER_TEAMS_ACTIVITIES = `${MEMBER_API_URL}member/teams/activities`;
export const MEMBER_LEARNERS = `${MEMBER_API_URL}member/learners`;
export const MEMBER_PROMOTERS = `${MEMBER_API_URL}member/promoters`;
export const MEMBER_QUALIFIED = `${MEMBER_API_URL}member/qualified`;

//  KPI
export const KPI_ACHIEVEMENTS = `${MEMBER_API_URL}kpi/achievements`;
export const KPI_HISTORIES = `${MEMBER_API_URL}kpi/histories`;
export const KPI_HISTORIES_DAILY = `${MEMBER_API_URL}kpi/histories/daily`;
export const KPI_LEADERS = `${MEMBER_API_URL}kpi/leaders`;

//  Apps
export const APP_SHIPPING = `${MEMBER_API_URL}app/shipping`;
export const APP_PAYMENT = `${MEMBER_API_URL}app/payment`;
export const APP_FACEBOOK_GROUP = `${MEMBER_API_URL}app/facebook-group`;

//  Liff
export const LIFF_CATEGORIES = `${MEMBER_API_URL}liff/categories`;
export const LIFF_CONTENTS = `${MEMBER_API_URL}liff/contents`;
export const LIFF_CONTENT_LOG = `${MEMBER_API_URL}liff/log`;

//  Couriers
export const COURIERS = `${MEMBER_API_URL}utils/couriers`;

//  Content
export const CONTENTS = `${MEMBER_API_URL}content/contents`;
export const CONTENT_CATEGORIES = `${MEMBER_API_URL}content/categories`;
export const CONTENT_LOG = `${MEMBER_API_URL}content/log`;
export const KPI_CONTENT_LOG = `${MEMBER_API_URL}content/kpi/log`;

//  Accounts
export const ACCOUNTS = `${MEMBER_API_URL}account/accounts`;
export const ACCOUNT_PROFILE = `${MEMBER_API_URL}account/profile`;
export const ACCOUNT_ORDERS = `${MEMBER_API_URL}account/orders`;

//  Common
export const COMMON_PROJECT_PRIVACY = `${API_URL}common/project/privacy`;

export const COMMON_BOXES = `${API_URL}common/shipping/boxes`;
export const COMMON_COURIERS = `${API_URL}common/shipping/couriers`;
export const COMMON_PAYMENT_METHODS = `${API_URL}common/payment/methods`;
export const COMMON_PAYMENT_BANKS = `${API_URL}common/payment/banks`;

//  Other article
export const ARTICLES = `${MEMBER_API_URL}articles`;

//  Lead
export const LEADS = `${MEMBER_API_URL}leads`;

//  Utils
export const ADDRESS = `${API_URL}common/address`;

//  Common

//  Others
export const PROVINCES = `${API_URL}data/provinces.json`;
export const BANKS = `${API_URL}data/banks.json`;

export const LINE_AUTH = 'https://notify-bot.line.me/oauth/authorize?';
export const LINE_TOKEN = 'https://notify-bot.line.me/oauth/token';
export const LINE_NOTIFY = 'https://notify-api.line.me/api/notify';
